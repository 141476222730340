"use client";
import React, { useState } from "react";
import {
  Modal,
  ModalBody,
  ModalContent,
  // useDisclosure,
} from "@nextui-org/react";
import { useDictionary } from "../../context/dictionaryProvider";
import { ContactWrapShape, SecondaryBtn, SendBtn } from "../molecules";
import InputTextField from "../atoms/InputTextField";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { staticFormsActions } from "../../actions/staticForms";
import { bitrixLeadAction } from "../../actions/bitrix";
import { bitrixSourceIds, senderEmail } from "../../lib/util";
import ThankuModal from "./ThankuModal";
import Loading from "../../app/[lang]/loading";
import PhoneField from "../atoms/PhoneField";
import { useSearchParams } from "next/navigation";
import { leadTemplate } from "../../emailTemplates/leadTemplate";
import { emailAction } from "../../actions/email";

const phoneRegex = /^\+\d{1,3}\s?\d{4,14}$/;

const formSchema = yup
  .object({
    name: yup.string().required(),
    email: yup.string().email().required(),
    phone: yup
      .string()
      .matches(phoneRegex, "Phone number is not valid")
      .required("Phone number is required"),
    message: yup.string().trim().required(),
  })
  .required();

const EnquireModal = ({ isOpen = false, onOpenChange, course }) => {
  const searchParams = useSearchParams();
  const dict = useDictionary();
  const [response, setResponse] = useState("");
  const [thankyou, setThankyou] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    trigger,
    formState: { errors, isSubmitting, isDirty },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  const onSubmit = async (data) => {
    try {
      const res = await staticFormsActions("EnquireNow", data);

      await bitrixLeadAction(
        { ...data, course },
        searchParams,
        bitrixSourceIds.enquireNow
      );

      if (res) setThankyou((prev) => !prev);
      try {
        const html = leadTemplate({ type: "Enquiry", ...data });
        await emailAction(senderEmail, html);
      } catch (error) {
        console.log(`Error while sending email`, error?.message);
      }
      reset();
    } catch (error) {
      console.log(`Error form submission: EnquireNow`, error?.message);
    }
  };

  const splitName = (fullName) => {
    const nameParts = fullName.trim().split(" ");
    return {
      firstName: nameParts[0] || "",
      lastName: nameParts.slice(1).join(" ") || "",
    };
  };

  const trackEquiryFormClick = (menuName, name, email, phone, message) => {
    const { firstName, lastName } = splitName(name);
    
    webengage.user.setAttribute("we_email", email);
    webengage.user.setAttribute("we_last_name", lastName);
    webengage.user.setAttribute("we_first_name", firstName);
    webengage.user.setAttribute("we_phone", phone);
    const userId = webengage.state.getForever().luid
    webengage.user.login(email);
    webengage.track('EnquiryForm', {
      category: 'EnquiryForm',
      label: menuName,
      value: 1,
      userName: name, 
      userEmail: email, 
      userPhone: phone,
      userMessage: message, 
    });
  };

  return (
    <>
      {isOpen && (
        <Modal
          backdrop="blur"
          isOpen={isOpen}
          size="xl"
          classNames={{
            backdrop: "blur-md",
          }}
          scrollBehavior="outside"
          onOpenChange={onOpenChange}
          onClose={reset}
        >
          <ModalContent>
            {(onClose) => (
              <>
                {!isSubmitting ? (
                  <ModalBody>
                    {!thankyou ? (
                      <form
                        onSubmit={handleSubmit(onSubmit)}
                        className="relative bg-[#fff] my-12 md:mx-10"
                      >
                        <div className="px-12 pb-28 pt-10 rounded-2xl border border-black">
                          <h3 className="text-2xl text-[#303030] mb-10 font-bold capitalize">
                            {dict?.enquireNow}
                          </h3>
                          <InputTextField
                            label={dict?.form.fieldNames.name}
                            type={"text"}
                            isInvalid={!!errors?.name}
                            errorMessage={errors?.name?.message}
                            {...register("name")}
                          />
                          <InputTextField
                            label={dict?.form.fieldNames.email}
                            type={"email"}
                            isInvalid={!!errors?.email}
                            errorMessage={errors?.email?.message}
                            {...register("email")}
                          />
                          <PhoneField
                            label={dict?.form.fieldNames.phone}
                            value={getValues("phone")}
                            onChange={async (value) => {
                              setValue("phone", value, {
                                shouldDirty: true,
                                shouldValidate: isDirty,
                              });
                            }}
                            isInvalid={!!errors?.phone}
                            errorMessage={errors?.phone?.message}
                          />
                          <InputTextField
                            label={dict?.form.fieldNames.message}
                            type={"text"}
                            isInvalid={!!errors?.message}
                            errorMessage={errors?.message?.message}
                            {...register("message")}
                          />
                          {response}
                        </div>
                        <SendBtn
                          type="submit"
                          className="!p-4"
                          id="enquire-now"
                          onClick={() => {
                            trackEquiryFormClick(
                              dict?.enquireNow || 'Enquiry Now',
                              getValues().name,
                              getValues().email,
                              getValues().phone,
                              getValues().message
                            );
                          }}
                        >
                          {dict?.sendNow}
                        </SendBtn>
                        <ContactWrapShape />
                      </form>
                    ) : (
                      <ThankuModal
                      course={course}
                        message={
                          "Thank you for submitting your query! Our team will get back to you soon!"
                        }
                        btnText={"Close"}
                        handleClick={() => {
                          onOpenChange();
                          setThankyou((prev) => !prev);
                        }}
                      />
                    )}
                  </ModalBody>
                ) : (
                  <Loading />
                )}
              </>
            )}
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default EnquireModal;
