"use client";
import { Divider, useDisclosure } from "@nextui-org/react";
import React, { useEffect, useState } from "react";
import EnquireModal from "../../modal/EnquireModal";
import { getCountryCodeAction } from "../../../actions/staticForms";

const contactUs = [
  {
    text: "+971 (04) 4038000",
    href: "tel:+971 (04) 4038000",
    id: "contact-telephone",
    code: "std",
  },
  {
    text: "+971 (04) 4038000",
    href: "tel:+971 (04) 4038000",
    id: "contact-dubai",
    code: "AE",
  },
  {
    text: "+1 347 637 6133",
    href: "tel:+1 347 637 6133",
    id: "contact-usa",
    code: "US",
  },
  {
    text: "+44 20 4524 4199",
    href: "tel:+44 20 4524 4199",
    id: "contact-uk",
    code: "UK",
  },
  {
    text: "+91 97462 22034",
    href: "tel:+91 97462 22034",
    id: "contact-india",
    code: "IN",
  },
];

const CallIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="inline-block me-2"
    >
      <path
        d="M14.0497 6C15.0264 6.19057 15.924 6.66826 16.6277 7.37194C17.3314 8.07561 17.8091 8.97326 17.9997 9.95M14.0497 2C16.0789 2.22544 17.9713 3.13417 19.4159 4.57701C20.8606 6.01984 21.7717 7.91101 21.9997 9.94M10.2266 13.8631C9.02506 12.6615 8.07627 11.3028 7.38028 9.85323C7.32041 9.72854 7.29048 9.66619 7.26748 9.5873C7.18576 9.30695 7.24446 8.96269 7.41447 8.72526C7.46231 8.65845 7.51947 8.60129 7.63378 8.48698C7.98338 8.13737 8.15819 7.96257 8.27247 7.78679C8.70347 7.1239 8.70347 6.26932 8.27247 5.60643C8.15819 5.43065 7.98338 5.25585 7.63378 4.90624L7.43891 4.71137C6.90747 4.17993 6.64174 3.91421 6.35636 3.76987C5.7888 3.4828 5.11854 3.4828 4.55098 3.76987C4.2656 3.91421 3.99987 4.17993 3.46843 4.71137L3.3108 4.86901C2.78117 5.39863 2.51636 5.66344 2.31411 6.02348C2.08969 6.42298 1.92833 7.04347 1.9297 7.5017C1.93092 7.91464 2.01103 8.19687 2.17124 8.76131C3.03221 11.7947 4.65668 14.6571 7.04466 17.045C9.43264 19.433 12.295 21.0575 15.3284 21.9185C15.8928 22.0787 16.1751 22.1588 16.588 22.16C17.0462 22.1614 17.6667 22 18.0662 21.7756C18.4263 21.5733 18.6911 21.3085 19.2207 20.7789L19.3783 20.6213C19.9098 20.0898 20.1755 19.8241 20.3198 19.5387C20.6069 18.9712 20.6069 18.3009 20.3198 17.7333C20.1755 17.448 19.9098 17.1822 19.3783 16.6508L19.1835 16.4559C18.8339 16.1063 18.6591 15.9315 18.4833 15.8172C17.8204 15.3862 16.9658 15.3862 16.3029 15.8172C16.1271 15.9315 15.9523 16.1063 15.6027 16.4559C15.4884 16.5702 15.4313 16.6274 15.3644 16.6752C15.127 16.8453 14.7828 16.904 14.5024 16.8222C14.4235 16.7992 14.3612 16.7693 14.2365 16.7094C12.7869 16.0134 11.4282 15.0646 10.2266 13.8631Z"
        stroke="#fff"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const EnrollNowIcon = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="inline-block me-2"
    >
      <g clip-path="url(#clip0_54_10140)">
        <path
          d="M7.11426 7.35754C6.97619 7.35754 6.86426 7.24561 6.86426 7.10754C6.86426 6.96947 6.97619 6.85754 7.11426 6.85754"
          stroke="white"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7.11426 7.35754C7.25233 7.35754 7.36426 7.24561 7.36426 7.10754C7.36426 6.96947 7.25233 6.85754 7.11426 6.85754"
          stroke="white"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M4.36426 7.35754C4.22619 7.35754 4.11426 7.24561 4.11426 7.10754C4.11426 6.96947 4.22619 6.85754 4.36426 6.85754"
          stroke="white"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M4.36426 7.35754C4.50233 7.35754 4.61426 7.24561 4.61426 7.10754C4.61426 6.96947 4.50233 6.85754 4.36426 6.85754"
          stroke="white"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9.86426 7.35754C9.72619 7.35754 9.61426 7.24561 9.61426 7.10754C9.61426 6.96947 9.72619 6.85754 9.86426 6.85754"
          stroke="white"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9.86426 7.35754C10.0023 7.35754 10.1143 7.24561 10.1143 7.10754C10.1143 6.96947 10.0023 6.85754 9.86426 6.85754"
          stroke="white"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7.11045 0.668092C5.94563 0.668455 4.80271 0.984759 3.80343 1.58331C2.80416 2.18187 1.98595 3.04026 1.43595 4.06706C0.885952 5.09386 0.624762 6.25062 0.6802 7.41412C0.735637 8.57763 1.10563 9.70431 1.75076 10.6741L0.670898 13.5472L4.28695 12.8933C5.15769 13.3188 6.11323 13.5423 7.08236 13.5471C8.05149 13.5519 9.0092 13.3379 9.88411 12.921C10.759 12.5042 11.5286 11.8952 12.1353 11.1395C12.7421 10.3839 13.1706 9.50104 13.3886 8.55675C13.6067 7.61247 13.6089 6.63114 13.3949 5.68592C13.1809 4.74069 12.7564 3.85595 12.1529 3.09765C11.5494 2.33934 10.7825 1.72704 9.90944 1.30637C9.03636 0.885701 8.07959 0.66752 7.11045 0.668092Z"
          stroke="white"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_54_10140">
          <rect
            width="14"
            height="14"
            fill="white"
            transform="translate(0.112305 0.107544)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

const FloatingButton = ({ course }) => {
  const [callTo, setCallTo] = useState(contactUs[0]);
  const { isOpen, onOpenChange, onOpen } = useDisclosure();
  const lat = localStorage.getItem("userLatitude");
  const long = localStorage.getItem("userLongitude");

  useEffect(() => {
    if (lat && long) {
      (async () => {
        const data = await getCountryCodeAction(lat, long);
        const callTo = contactUs.find((contact) => contact.code == data);
        setCallTo(callTo || contactUs[0]);
      })();
    }
  }, [lat, long]);

  if (!course) return null;

  return (
    <>
      <div className="fixed bottom-0 z-30 w-full block sm:hidden">
        <div className="grid grid-cols-[1fr_10%_1fr] bg-[#0096ac] text-white p-4 place-content-center place-items-center justify-center">
          <span onClick={onOpen} className="">
            <EnrollNowIcon />
            Enquire Now
          </span>
          <Divider orientation="vertical" className="bg-[#ffffff85]" />
          <span>
            <a href={callTo.href} id={callTo.id}>
              <CallIcon /> Call Now
            </a>
          </span>
        </div>
      </div>
      {isOpen && (
        <EnquireModal
          isOpen={isOpen}
          onOpenChange={onOpenChange}
          course={course}
        />
      )}
    </>
  );
};

export default FloatingButton;
