import(/* webpackMode: "eager" */ "/home/ubuntu/lp-frontend/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/home/ubuntu/lp-frontend/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/home/ubuntu/lp-frontend/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/home/ubuntu/lp-frontend/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/home/ubuntu/lp-frontend/src/app/[lang]/[slug]/curriculumLink.jsx");
;
import(/* webpackMode: "eager" */ "/home/ubuntu/lp-frontend/src/app/[lang]/browsecourses/[type]/[slug]/BrochureCourse.jsx");
;
import(/* webpackMode: "eager" */ "/home/ubuntu/lp-frontend/src/app/[lang]/browsecourses/[type]/[slug]/Enquiry.jsx");
;
import(/* webpackMode: "eager" */ "/home/ubuntu/lp-frontend/src/app/[lang]/browsecourses/[type]/[slug]/UpcomingSession.jsx");
;
import(/* webpackMode: "eager" */ "/home/ubuntu/lp-frontend/src/app/[lang]/browsecourses/EnrolledValue.jsx");
;
import(/* webpackMode: "eager" */ "/home/ubuntu/lp-frontend/src/app/[lang]/browsecourses/Objective.jsx");
;
import(/* webpackMode: "eager" */ "/home/ubuntu/lp-frontend/src/app/[lang]/browsecourses/Overview.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/ubuntu/lp-frontend/src/assets/svg/ArrowRightIcon.jsx");
;
import(/* webpackMode: "eager" */ "/home/ubuntu/lp-frontend/src/components/Academy.jsx");
;
import(/* webpackMode: "eager" */ "/home/ubuntu/lp-frontend/src/components/Certificate.jsx");
;
import(/* webpackMode: "eager" */ "/home/ubuntu/lp-frontend/src/components/ClientReview.jsx");
;
import(/* webpackMode: "eager" */ "/home/ubuntu/lp-frontend/src/components/courses/Curriculum.jsx");
;
import(/* webpackMode: "eager" */ "/home/ubuntu/lp-frontend/src/components/courses/modals/FloatingButton.jsx");
;
import(/* webpackMode: "eager" */ "/home/ubuntu/lp-frontend/src/components/courses/TrainerCarousel.jsx");
;
import(/* webpackMode: "eager" */ "/home/ubuntu/lp-frontend/src/components/DemandCourses.jsx");
;
import(/* webpackMode: "eager" */ "/home/ubuntu/lp-frontend/src/components/faq/index.jsx");
;
import(/* webpackMode: "eager" */ "/home/ubuntu/lp-frontend/src/components/LazyLoader.jsx");
;
import(/* webpackMode: "eager" */ "/home/ubuntu/lp-frontend/src/components/molecules/index.jsx");
;
import(/* webpackMode: "eager" */ "/home/ubuntu/lp-frontend/src/components/molecules/RightEnrollWrapper.jsx");
;
import(/* webpackMode: "eager" */ "/home/ubuntu/lp-frontend/src/components/ourClients/index.jsx");
;
import(/* webpackMode: "eager" */ "/home/ubuntu/lp-frontend/src/components/SocialRating/index.jsx");
;
import(/* webpackMode: "eager" */ "/home/ubuntu/lp-frontend/src/components/StarRating.jsx");
;
import(/* webpackMode: "eager" */ "/home/ubuntu/lp-frontend/src/components/VideoPlayer.jsx");
